// VARIABLES
// by Ivan Videnovic Web Development
//========================================

/*========= Colors =========*/
$color-primary: #9575CD;
// color variation constant
$dark-light: 10%;

@import 'functions-colors';

$color-primary-dark: color-primary-dark();
$color-primary-light: color-primary-light();

$color-secondary: color-secondary();
$color-secondary-dark: color-secondary-dark();
$color-secondary-light: color-secondary-light();


/*========= Transitions =========*/
$trans-time: 1s;