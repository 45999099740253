/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Sep 11, 2017, 11:26:43 AM
    Author     : ivan
*/

* {
    margin: 0;
    padding: 0;
}

#header h1 {
    margin-top: 200px;
}