/* Basic HTML Elements
====================================================== */
body {
  font-family: 'Raleway', sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
}


#header {
  background-image: url('../images/coffee-header-bw-bg.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

/* HEADER CSS
====================================================== */
.header-overlay {
  background-color: rgba($color-primary, 0.7);
  height: 100vh;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-primary-dark;
    text-decoration: none;
  }
}

.coffee-shop {
  border-bottom: 2px solid $color-secondary;
  margin: 20px 0;
  padding: 0 0 20px 0;


  img{
    border: 5px solid $color-primary;
    border-radius: 25%;
    padding: 2px;
  }

  .name {
    font-size: 3rem;
  }

  .distance-desc {
    text-align: center;
  }

  .distance {
    font-size: 2rem;
    text-align: center;
  }

}


.logo {
  padding-bottom: 50px;
}

.header-text h1 {
  color: #fff;
  font-size: 35px;
  font-weight: 300;
  line-height: 45px;
  padding-top: 30px;
  text-transform: uppercase;
}

.header-text p {
  color: #fff;
  font-size: 17px;
  line-height: 28px;
  font-weight: 300;
}

.header-btns {
  margin-top: 90px;
  margin-bottom: 50px;
}

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
  background: transparent;
  border-color: blue;
  padding: 10px 30px;
  font-size: 19px;
  text-transform: uppercase;
  border-radius: 3px;
  transition: all $trans-time;
}

.btn-download:hover {
  background-color: blue;
  color: #fff;
}

.btn-tour,
.btn-tour:focus,
.btn-tour:active,
.btn-tour:visited
{
  color: #A9ADB0;
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 500;
  background: transparent;
  transition: all $trans-time;
}

.btn-tour:hover {
  color: #fff;
}

.header-iphone img{
  width: 100%;
}

p {
  font-size: 14px;
}

#main {
  height: 1000px;
}

/* Generic CSS
====================================================== */
.section-title {
  padding-top: 90px;
  padding-bottom: 50px;
}

.section-title h2 {
  position: relative;
  text-align: center;
  color: gray;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
}

.section-title h2:after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 60px;
  height: 1px;
  background-color: blue;
  margin-left: -30px;
}

.section-title p {
  color: gray;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  text-align: center;
  margin-top: 45px;
}


